import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react'
import featureFlags from 'constants/featureFlags'
import { seenTutorialBase } from 'constants/tutorials'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useJamesMutation from 'hooks/useJamesMutation'
import useUserLanguage from 'hooks/useUserLanguage'
import info from 'info.json'
import { sideMenuFiltersStorageKey } from 'pages/Shifts/useStoredFilters'
import { useSetDatadogUser } from 'services/Datadog/useSetDatadogUser'
import FlashMessagesContext from 'services/FlashMessages/context'
import { isNativeNoOverride } from 'utils/platform'
import { useApolloClient, useMutation } from '@apollo/client'
import { Filesystem, Directory, FileInfo } from '@capacitor/filesystem'
import { datadogLogs } from '@datadog/browser-logs'
import { AuthenticationKind } from '../../constants/authentication'
import { MeObject, AuthPersonIdAndPermissions } from '../../types'
import SessionContext, { ApiMeResponseT } from './context'
import { sessionQuery, logoutMutationParams, logoutDeviceMutationParams, RememberMeParams } from './queries'
import useRefreshPermissions from './useRefreshPermissions'
import { retryOperation } from './utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: ApiMeResponseT = { auth: -1 as any, cmsActivated: false }
const initialPersons: AuthPersonIdAndPermissions[] = []

type AuthRefreshTokenResponse = {
  authRefreshToken?: MeObject
}
interface SessionProps {
  children?: React.ReactNode
}

const SessionService: React.FC<SessionProps> = ({ children }) => {
  const [session, setSession] = useState(initialState)
  const [registeredToken, setRegisteredToken] = useState<string | null>(null)
  const [persons, setPersons] = useState(initialPersons)
  const { t } = useFavurTranslation()

  const [rememberMeMutation] = useJamesMutation(RememberMeParams)
  const { setUserLanguage } = useUserLanguage()
  const { setFlashMessage } = useContext(FlashMessagesContext)
  const client = useApolloClient()

  // Sets the user permissions if logged in
  const { refetch: refetchPersons } = useRefreshPermissions(setPersons, session.auth < 1)
  useSetDatadogUser()

  const clearLocalStorage = () => {
    const ignored = [...Object.keys(featureFlags), sideMenuFiltersStorageKey]
    const ignoredByStart = [seenTutorialBase]
    Object.keys(localStorage).forEach((key) => {
      if (ignored.includes(key) || ignoredByStart.some((value) => key.startsWith(value))) return
      localStorage.removeItem(key)
    })
  }

  const clearFilesInCache = async () => {
    if (isNativeNoOverride()) {
      await Filesystem.readdir({
        path: '/',
        directory: Directory.Cache,
      }).then((readdirResult) => {
        readdirResult.files.map(async (file: FileInfo) => {
          if (file.type === 'file') {
            await Filesystem.deleteFile({
              path: file.name,
              directory: Directory.Cache,
            })
          }
        })
      })
    }
  }

  const cleanSession = useCallback(
    (newSession?: ApiMeResponseT) => {
      // Clean storages
      clearLocalStorage()
      sessionStorage.clear()

      setSession(newSession || { auth: 0, cmsActivated: false })
      setRegisteredToken(null)
      setPersons(initialPersons)

      // Clear apollo cache
      client.clearStore()

      // Clear direcory ~/Librabry/Cache
      clearFilesInCache()
    },
    [client],
  )

  const logoutQuery = useMemo(() => {
    if (isNativeNoOverride() && registeredToken) {
      return logoutDeviceMutationParams(registeredToken)
    }

    return logoutMutationParams
  }, [registeredToken])

  const [doLogout] = useMutation<{ logout: ApiMeResponseT }>(logoutQuery)

  const logout = useCallback(() => {
    doLogout().then((res) => {
      cleanSession(res?.data?.logout)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registeredToken])

  const rememberMeExecutedRef = useRef(false)

  const rememberMe = useCallback(() => {
    if (rememberMeExecutedRef.current) {
      datadogLogs.logger.info('[Doing remember me] Remember me reference already exists')
      return
    }

    rememberMeExecutedRef.current = true

    retryOperation({
      operation: async () => {
        const data: AuthRefreshTokenResponse = await rememberMeMutation()
        const sessionData: ApiMeResponseT = {
          auth: data?.authRefreshToken?.auth || AuthenticationKind.ANONYMOUS,
          cmsActivated: data?.authRefreshToken?.cmsActivated ?? false,
          phone: data?.authRefreshToken?.user?.phoneNumber,
          name: data?.authRefreshToken?.user
            ? `${data.authRefreshToken.user.firstName} ${data.authRefreshToken.user.lastName}`
            : '',
          personIds: data?.authRefreshToken?.personIds || [],
          personPackages: data?.authRefreshToken?.personPackages || {},
        }

        setSession(sessionData)
      },
      handleOperationError: () => {
        setFlashMessage(t('sessionService.errors.generic.message'))

        setSession({ auth: 0, cmsActivated: false })
      },
      name: 'Remember me',
    })
  }, [rememberMeMutation, setFlashMessage, t])

  const fetchSession = useCallback(
    (handleUnauthenticated: () => void) => {
      const operation = async () => {
        const res = await client.query<{ me: MeObject }>({
          query: sessionQuery,
          variables: { buildVersionNumber: info.version },
          fetchPolicy: 'no-cache',
        })

        const me = res?.data?.me

        if (me?.auth) {
          setUserLanguage(me?.user?.language as string)
          setSession(me)
          refetchPersons()
        } else {
          handleUnauthenticated()
        }
      }
      retryOperation({
        operation: operation,
        handleOperationError: () => {
          datadogLogs.logger.error('[Doing logout] There was an unexpected error fetching session')
          setFlashMessage(t('sessionService.errors.generic.message'))
          logout()
        },
        name: 'Fetching session',
      })
    },
    [client, logout, refetchPersons, setFlashMessage, setUserLanguage, t],
  )

  const refreshWithRememberMe = useCallback(() => {
    fetchSession(() => rememberMe())
  }, [fetchSession, rememberMe])

  const refresh = useCallback(() => {
    fetchSession(() => {
      datadogLogs.logger.error('[Doing logout] Handling unauthenticated user when fetching session')
      logout()
    })
  }, [fetchSession, logout])

  // Refetch session on didmount of service provider, basically happens only on hard reload.
  useEffect(() => {
    refreshWithRememberMe()
  }, [refreshWithRememberMe])

  return (
    <SessionContext.Provider
      value={{
        ...session,
        update: setSession,
        logout,
        cleanSession,
        refresh,
        registeredToken,
        setRegisteredToken,
        persons,
        setPersons,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export default SessionService
