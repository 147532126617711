import React from 'react'
import LoadMoreButton from 'components/LoadMoreButton'
import { useHistory } from 'react-router-dom'
import TaskListHeader from 'redesign/components/organisms/Headers/TaskListHeader'
import { TaskList } from 'redesign/components/templates/ContentLists'
import Page from 'redesign/components/templates/Page'
import { useTaskList } from './useTaskList'

const TaskListPage: React.FC = () => {
  const { list: tasks, isOffice, loading, loadMore, showLoadMoreButton } = useTaskList()
  const history = useHistory()
  const redirect = ({ taskUrl }: { taskUrl: string }) => history.push(taskUrl)

  return (
    <Page header={<TaskListHeader isOffice={isOffice} />} paddingX={0}>
      <TaskList isLoading={loading} isOffice={isOffice} tasks={tasks} redirect={redirect} />
      {showLoadMoreButton && <LoadMoreButton onClick={loadMore} testId="task-list-load-more" />}
    </Page>
  )
}
export default TaskListPage
