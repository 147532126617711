import useRolesViews from 'hooks/useRolesViews'
import useTasksQueryPaginated from 'redesign/shared/hooks/useTasksQueryPaginated'
import { TASK_LIMIT } from '../../components/templates/ContentLists/TaskList/constants'

export const useTaskList = () => {
  const { isOffice } = useRolesViews()
  const {
    updateFilters,
    updateOffice,
    hasMoreElements,
    list: tasks,
    loading: tasksLoading,
    loadMore,
    reload,
  } = useTasksQueryPaginated({ initialOffice: isOffice, limit: TASK_LIMIT, initialFilters: {} })

  return {
    isOffice,
    updateFilters,
    updateOffice,
    hasMoreElements,
    list: tasks ?? [],
    loading: tasksLoading,
    loadMore,
    reload,
    showLoadMoreButton: hasMoreElements && !tasksLoading,
  }
}
