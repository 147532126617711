import React, { useState } from 'react'
import { CircularProgress } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { MonthlySheetDialogDataT } from 'pages/Documents/DocumentList/types'
import useDocumentsQueryPaginated from 'pages/Documents/DocumentList/useDocumentsQueryPaginated'
import LoadMoreButton from 'redesign/components/atoms/Buttons/LoadMoreButton'
import ListWrapper from 'redesign/components/organisms/ListWrapper'
import DocumentCard from './DocumentCard'
import DocumentDialog from './DocumentDialog'
import { DEFAULT_DIALOG_STATE } from './constants'
import { ContentContainer } from './styles'

const DocumentsList: React.FC = () => {
  const { list: documents, hasMoreElements, loadMore, loading } = useDocumentsQueryPaginated()
  const [dialog, setDialog] = useState<MonthlySheetDialogDataT>(DEFAULT_DIALOG_STATE)
  const { t } = useFavurTranslation()

  return (
    <ContentContainer>
      <ListWrapper
        hasContent={Boolean(documents?.length)}
        message={t('page.documents.content.noDocuments')}
        isLoading={loading}
      >
        {documents?.map((document) => (
          <DocumentCard key={document.favurUuid} document={document} openMonthlySheetDialog={setDialog} />
        ))}
        {loading && <CircularProgress />}
        {hasMoreElements && !loading && <LoadMoreButton onClick={loadMore} testId="documents-load-more" />}
      </ListWrapper>
      <DocumentDialog dialog={dialog} onClose={() => setDialog(DEFAULT_DIALOG_STATE)} />
    </ContentContainer>
  )
}

export default DocumentsList
